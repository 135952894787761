<template>
  <Transition
    enter-active-class="transition duration-300 ease-out"
    enter-from-class="transform translate-y-4 opacity-0"
    enter-to-class="transform translate-y-0 opacity-100"
    leave-active-class="transition duration-200 ease-in"
    leave-from-class="transform translate-y-0 opacity-100"
    leave-to-class="transform translate-y-4 opacity-0"
  >
    <UCard
      v-if="showBanner"
      :ui="{
        root: 'fixed bottom-0 left-0 m-8 z-100 max-w-sm bg-neutral-950/60 backdrop-blur-lg',
        footer: 'p-2 sm:p-4',
      }"
    >
      <p class="font-medium text-neutral-400 dark:text-gray-300">
        We use cookies to enhance your experience. By continuing to visit this
        site you agree to our use of cookies.
      </p>
      <template #footer>
        <div class="flex justify-end space-x-2">
          <UButton
            variant="subtle"
            class="w-1/2 cursor-pointer justify-center text-center"
            @click="handleCookieConsent('decline')"
          >
            Decline
          </UButton>
          <UButton
            class="w-1/2 cursor-pointer justify-center text-center"
            @click="handleCookieConsent('accept')"
          >
            Accept
          </UButton>
        </div>
      </template>
    </UCard>
  </Transition>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const cookieConsent = ref<boolean | null>(null);
const showBanner = ref(false);

interface ConsentData {
  decision: 'accept' | 'decline';
  timestamp: number;
  expires: number;
}

const handleCookieConsent = (decision: 'accept' | 'decline') => {
  try {
    const consentData: ConsentData = {
      decision,
      timestamp: Date.now(),
      expires: Date.now() + 365 * 24 * 60 * 60 * 1000, // 1 year
    };

    localStorage.setItem('cookieConsent', JSON.stringify(consentData));
    cookieConsent.value = true;
    showBanner.value = false;

    if (decision === 'accept') {
      agreedToCookiesScriptConsent.accept();
    }
  } catch (error) {
    console.error('Error saving cookie consent:', error);
  }
};

const checkCookieConsent = () => {
  try {
    const storedConsent = localStorage.getItem('cookieConsent');

    if (!storedConsent) {
      cookieConsent.value = false;
      showBanner.value = true;
      return;
    }

    const consentData = JSON.parse(storedConsent) as ConsentData;
    const now = Date.now();

    // Validate the parsed data has the expected structure
    if (consentData && consentData.expires && consentData.decision) {
      if (now < consentData.expires) {
        cookieConsent.value = true;
        if (consentData.decision === 'accept') {
          agreedToCookiesScriptConsent.accept();
        }
      } else {
        // Consent expired
        localStorage.removeItem('cookieConsent');
        cookieConsent.value = false;
        showBanner.value = true;
      }
    } else {
      // Invalid data structure
      localStorage.removeItem('cookieConsent');
      cookieConsent.value = false;
      showBanner.value = true;
    }
  } catch (error) {
    // Handle any JSON parsing errors
    console.error('Error checking cookie consent:', error);
    localStorage.removeItem('cookieConsent');
    cookieConsent.value = false;
    showBanner.value = true;
  }
};

onMounted(() => {
  checkCookieConsent();
});
</script>
